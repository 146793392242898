import sentry from './sentry'
import { CaptureContext } from './type'
import { Business } from '../sentryCaptureException'

const { fatal, error, warn, info } = sentry

export const logger = {
  fatal,
  error,
  warn,
  info
}

const addBizTag = (bizTag: Business = Business.Trade) => {
  return (context: CaptureContext = {}) => {
    const { tags = {} } = context
    const bizTags = {
      ...tags,
      business: bizTag
    }
    return { ...context, tags: bizTags }
  }
}

type ContextHandler = (
  context: CaptureContext,
  bizTag?: Business
) => CaptureContext
const getLogger = (contextHandler: ContextHandler) => {
  return {
    fatal: (
      label: string,
      describe: string,
      err: Error,
      context: CaptureContext = {}
    ) => {
      fatal(label, describe, err, contextHandler(context))
    },
    error: (
      label: string,
      describe: string,
      err: Error,
      context: CaptureContext = {}
    ) => {
      error(label, describe, err, contextHandler(context))
    },
    warn: (
      label: string,
      describe: string,
      err: Error,
      context: CaptureContext = {}
    ) => {
      warn(label, describe, err, contextHandler(context))
    },
    info: (describe: string, context: CaptureContext = {}) => {
      info(describe, contextHandler(context))
    }
  }
}

export const loggerT = getLogger(addBizTag(Business.Trade))
export const loggerA = getLogger(addBizTag(Business.OAUTH))

export default logger
