import axios from 'axios'

axios.interceptors.response.use((response) => {
  return response
})

export const apiInstance = axios.create({
  baseURL: 'https://api.trongrid.io'
})

/**
 * all triggersmartcontract
 */
export const trc20Triggersmartcontract = async (params: any) => {
  return await apiInstance.post('/wallet/triggersmartcontract', params)
}
