import { coinWithBalance, Transaction } from '@mysten/sui/transactions'
import {
  GetTransactionBlockParams,
  SuiClient,
  SuiTransactionBlockResponse
} from '@mysten/sui/client'
import { formatUnits } from 'viem'
import { fromBase64, MIST_PER_SUI, SUI_TYPE_ARG } from '@mysten/sui/utils'
import chains from '@/proviers/web3Provider/chains'
const suiEndPoint = 'https://fullnode.mainnet.sui.io:443'
export const SUI_TOKEN_ADDRESS = SUI_TYPE_ARG
let suiClient: SuiClient = new SuiClient({ url: suiEndPoint })
export const suiScanUrl = 'https://suiscan.xyz/mainnet/tx/'
export const suiTokenScanUrl = 'https://suiscan.xyz/mainnet/coin/'

export const mockSuiChainId = 784

export type SendSuiTransactionParams = {
  bytes?: Uint8Array
  transaction?: string
  transactionBlock: string
  chainId: number
  data?: any
}

export type SendSuiParsedTXParams = {
  to: string
  from: string
  amount: string
  value?: string
  contractAddr?: string
}

export function getSuiClient(): SuiClient {
  if (suiClient) {
    return suiClient
  }
  const client = new SuiClient({ url: suiEndPoint })
  suiClient = client
  return suiClient
}

export const sendSuiTx = async ({
  bytes,
  signature
}: {
  bytes: Uint8Array
  signature: string
}): Promise<SuiTransactionBlockResponse> => {
  const res = await suiClient.executeTransactionBlock({
    transactionBlock: bytes,
    signature: signature
  })
  return res
}

export const getSendSuiCoinTx = async ({
  fromAddress,
  toAddress,
  amount,
  coinType
}: {
  fromAddress: string
  toAddress: string
  amount: string
  coinType?: string
}): Promise<{ transaction: string; bytes: Uint8Array } | null> => {
  const txb = new Transaction()
  txb.transferObjects(
    [coinWithBalance({ balance: BigInt(amount), type: coinType || undefined })],
    toAddress
  )
  try {
    txb.setSender(fromAddress)
    const transaction = (await txb.toJSON({ client: suiClient })).toString()
    const bytes = await txb.build({ client: suiClient })
    return { transaction, bytes }
  } catch (e) {
    console.warn(e)
    return null
  }
}

// only form send
export interface GetSuiSendGasType {
  fromAddress: string
  toAddress: string
  amount: string
  coinType?: string
}

export const getSuiGasFromTx = async (tx: string) => {
  const txb = Transaction.from(tx)
  const bytes = await txb.build({ client: suiClient })
  try {
    const { effects } = await suiClient.dryRunTransactionBlock({
      transactionBlock: bytes
    })
    const totalGas =
      BigInt(effects.gasUsed.computationCost) +
      BigInt(effects.gasUsed.storageCost)
    return formatUnits(
      totalGas || 0n,
      chains.sui.chain?.nativeCurrency.decimals || 9
    )
  } catch (e) {
    console.warn(e)
    throw e
  }
}

export const getSuiSendGas = async ({
  fromAddress,
  toAddress,
  coinType
}: GetSuiSendGasType): Promise<string> => {
  const txb = new Transaction()

  txb.transferObjects(
    [coinWithBalance({ balance: BigInt('0'), type: coinType || undefined })],
    toAddress
  )
  txb.setSender(fromAddress)
  const bytes = await txb.build({ client: suiClient })
  try {
    const { effects } = await suiClient.dryRunTransactionBlock({
      transactionBlock: bytes
    })
    const totalGas =
      BigInt(effects.gasUsed.computationCost) +
      BigInt(effects.gasUsed.storageCost)
    return formatUnits(
      totalGas || 0n,
      chains.sui.chain?.nativeCurrency.decimals
    )
  } catch (e) {
    console.warn(e)
    return '0'
  }
}

export const querySuiTransaction = async ({
  digest,
  options
}: GetTransactionBlockParams) => {
  const result = await suiClient.getTransactionBlock({
    digest,
    options: { showEffects: true, ...options }
  })
  return result.effects?.status
}

export const parseSuiTx = async (tx: string) => {
  let txObj
  try {
    txObj = await parseTransferObjects(JSON.parse(tx))
  } catch (error) {
    console.error(error)
  }
  return txObj
}

async function parseTransferObjects(transactionData: {
  commands: any[]
  inputs: any[]
  sender: string
}): Promise<SendSuiParsedTXParams> {
  const USDT_ADDRESS =
    '0xc060006111016b8a020ad5b33834984a437aaa7d3c74c18e09a95d48aceab08c::coin::COIN'
  console.log('transactionData', transactionData)
  const commands = transactionData.commands
  const inputs = transactionData.inputs

  const transferCommand = commands.find((cmd) => cmd.TransferObjects)
  const coinCommand = commands.find((cmd) => cmd.SplitCoins)

  //  to address index
  const addressIndex = transferCommand.TransferObjects.address?.Input
  const addressBytes = fromBase64(inputs[addressIndex].Pure.bytes)
  const toAddress = `0x${Array.prototype.map
    .call(addressBytes, (x) => ('00' + x.toString(16)).slice(-2))
    .join('')}`

  // tx amount index
  const amountIndex = coinCommand.SplitCoins?.amounts?.[0]?.Input // 索引
  const amountBytes = inputs[amountIndex].Pure.bytes

  // coin index
  const coinIndex = coinCommand.SplitCoins?.coin?.Input
  const coinObjectId = inputs[coinIndex]?.Object?.ImmOrOwnedObject?.objectId
  let type = ''
  if (coinObjectId) {
    type = await getTokenTxType(coinObjectId)
  }

  const decodedStr = atob(amountBytes)
  let amount = 0
  for (let i = 0; i < decodedStr.length; i++) {
    amount |= decodedStr.charCodeAt(i) << (i * 8)
  }
  const suiAmount = Number.parseInt(amount + '') / Number(MIST_PER_SUI)

  console.log('transactionData', transactionData)

  return {
    amount: amount.toString(),
    value: suiAmount.toString(),
    to: toAddress,
    from: transactionData.sender,
    contractAddr: type
  }
}

// 使用 objectId 查询对象详情
async function getObjectDetails(objectId: string) {
  const objectDetails = await suiClient.getObject({
    id: objectId,
    options: {
      showContent: true,
      showType: true,
      showOwner: true
    }
  })

  return objectDetails
}

// 解析 token 交易对象 type
export async function getTokenTxType(objectId: string) {
  const objectDetails = await getObjectDetails(objectId)
  const objectType = objectDetails?.data?.type || ''
  const type = /(?<=<)[^>]+(?=>)/g.exec(objectType) || []
  console.log('type', type)
  return type[0] || ''
}
