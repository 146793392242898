import { tgDeskPlatform } from '@/utils/telegram'
import { useEffect } from 'react'
import useApp from '../oauth/useApp'
import commonStore from '@/stores/commonStore'

export const useFullScreen = () => {
  const { isValidActions } = useApp()

  useEffect(() => {
    const fullChanged = () => {
      const pla = window.Telegram?.WebApp.platform
      if (!tgDeskPlatform.includes(pla) && !isValidActions) {
        commonStore.updateFullScreen(true)
        const boddy = document.getElementsByTagName('body')[0] as any
        boddy.style = pla === 'ios' ? 'padding-top: 102px' : 'padding-top: 94px'
        if (!document.body.classList.contains('tmd-fullmode')) {
          document.body.classList.add('tmd-fullmode')
        }
      }
    }
    if (window.Telegram?.WebApp?.isFullscreen) {
      fullChanged()
    }
    window.Telegram.WebApp.onEvent('fullscreenChanged', fullChanged)
    return () => {
      window.Telegram?.WebApp.offEvent('fullscreenChanged', fullChanged)
    }
  }, [])
}
