// fork https://github.com/ant-design/ant-design-mobile/blob/master/src/components/index-bar/index.ts
import './index-bar.scss'
import { Panel } from './panel'
import { IndexBar } from './index-bar'
import { attachPropertiesToComponent } from '../utils/attach-properties-to-component'

export type {
  IndexBarProps as TIndexBarProps,
  IndexBarRef as TIndexBarRef
} from './index-bar'
export type { IndexBarPanelProps as TIndexBarPanelProps } from './panel'

const TIndexBar = attachPropertiesToComponent(IndexBar, {
  Panel
})
export { TIndexBar }
