import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'

const chain = {
  id: 4,
  name: 'COSMOS_HUB',
  nativeCurrency: {
    decimals: 6,
    name: 'ATOM',
    symbol: 'ATOM'
  }
}
const icon = chainsSvgs.cosmosPng
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: chain as any,
  id: 4,
  type: 'COSMOS',
  name: 'Cosmos Hub',
  icon: icon,
  networkType
}

export default chainInfo
