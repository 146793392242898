export const CURRENT_CACHE_VERSION = 'v3' // New version caching
const MIGRATION_COMPLETED = 'cacheMigrationCompleted_v1' // The overall migration is marked as complete
const MIGRATION_SKIPPED = 'cacheMigrationSkipped' // Skip the migration flag after emptying the cache

export const STORAGE_KEY = Object.freeze({
  LOGGEDIN_USER_ID: 'loggedInUserId',
  SHOW_TOKENS: '__SHOW_TOKENS',
  HIDDEN_TOKENS: '__HIDDEN_TOKENS',
  TOKENS_LIST: '__TOKENS_LIST',
  TRANSACTIONS: '__TRANSACTIONS',
  bindEmail: 'bindEmail',
  user: 'user',
  isClear: 'isClear',
  isFirstSwap: 'isFirstSwap',
  AI_TOKEN: 'AI_TOKEN',
  AI_DEFAULT_CHECKED_TRADE: 'AI_DEFAULT_CHECKED_TRADE',
  AI_POST: 'AI_POST'
})

// Obtain the current user ID (you need to implement it yourself according to the project situation)
export function getCurrentUserId() {
  let userId = null
  const userStr = localStorage.getItem(STORAGE_KEY.user)

  if (userStr && userStr !== 'null') {
    const userInfo = JSON.parse(userStr)
    userId = userInfo?.['id']
  }

  return userId
}

// Get the cache
export const getCache = (key: string, defaultValue = {}) => {
  const userId = getCurrentUserId()
  if (!userId) return defaultValue

  const userKey = `${key}_${userId}`
  const cachedData = localStorage.getItem(userKey)

  if (cachedData) {
    try {
      const { version, data } = JSON.parse(cachedData)
      if (version === CURRENT_CACHE_VERSION) {
        return data
      } else {
        return defaultValue
      }
    } catch (e) {
      console.error(`Error parsing cache for key ${key}:`, e)
      return defaultValue
    }
  }

  // If the cache does not exist, perform the migration
  migrateOldCache(key)

  // If the cache does not exist or is not the current version, the default value is returned
  return defaultValue
}

// Set up caching
export const setCache = (key: string, data: any) => {
  const userId = getCurrentUserId() // Get the latest user ID every time you make a call
  if (!userId) return

  const userKey = `${key}_${userId}`
  const cacheToStore = JSON.stringify({
    version: CURRENT_CACHE_VERSION,
    data
  })
  localStorage.setItem(userKey, cacheToStore)
}

// Except for caching (support by user ID)
export const removeCache = (key: string) => {
  const userId = getCurrentUserId()
  if (!userId) return

  const userKey = `${key}_${userId}`

  // Delete cached entries
  localStorage.removeItem(userKey)

  // Delete the migration marker
  const migratedKey = `${key}_migrated`
  localStorage.removeItem(migratedKey)

  console.log(`Cache and migration mark for key ${key} have been removed.`)
}

// Migrate old caches
export const migrateOldCache = (oldKey: string) => {
  const oldCache = localStorage.getItem(oldKey)
  if (oldCache) {
    const oldData = JSON.parse(oldCache)

    if (!oldData.version) {
      setCache(oldKey, oldData) // Migrate old data to the new format
      localStorage.removeItem(oldKey) // Delete the old cache
    }
  }
}

// Migrate a cached item and mark whether the migration is complete
const migrateAndMark = (key: string) => {
  const migratedKey = `${key}_migrated` // Set the migration tag for each cached item
  const migrationStatus = localStorage.getItem(migratedKey)

  if (!migrationStatus) {
    // If the cached items have not been migrated, run the migration logic
    migrateOldCache(key)

    // If the migration is complete, mark the cached item as migrated
    localStorage.setItem(migratedKey, 'true')
    console.log(`Cache for key ${key} migrated successfully.`)
  }
}

// Check if migration is required
export function checkAndMigrateCache() {
  // Check if the migration was skipped because the cache was emptied
  const migrationSkipped = localStorage.getItem(MIGRATION_SKIPPED)

  if (migrationSkipped) {
    console.log('Cache migration skipped due to empty cache.')
    return // If the tag exists, skip the migration directly
  }

  const migrationCompleted = localStorage.getItem(MIGRATION_COMPLETED)

  // If the overall migration is not complete
  if (!migrationCompleted) {
    const MIGRATION_KEYS = [STORAGE_KEY.SHOW_TOKENS, STORAGE_KEY.HIDDEN_TOKENS] // Cached items that need to be migrated
    let hasOldCache = false

    MIGRATION_KEYS.forEach((key) => {
      const oldCache = localStorage.getItem(key)
      if (oldCache) {
        hasOldCache = true
        migrateAndMark(key) // Each cached item is migrated and labeled
      }
    })

    // If all cached items have been migrated, the overall migration is marked
    if (
      MIGRATION_KEYS.every(
        (key) => localStorage.getItem(`${key}_migrated`) === 'true'
      )
    ) {
      localStorage.setItem(MIGRATION_COMPLETED, 'true')
      console.log('All caches have been migrated successfully.')
    } else if (!hasOldCache) {
      // If no old caches are found, the tag skips the migration
      localStorage.setItem(MIGRATION_SKIPPED, 'true')
      console.log('No old caches found, migration skipped.')
    } else {
      console.log('Some caches are still pending migration.')
    }
  }
}
