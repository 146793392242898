import type { CaptureFunc, CaptureContext } from './type'
import * as Sentry from '@sentry/react'

import { ErrorLevel } from '../const'
import { errorHandler } from './errorHandler'

/**
 * 上报错误
 * @param level 错误级别， "fatal" | "error" | "warn"
 * @param label 标记 标示自己的业务模块 必填
 * @param describe 自定义信息 必填
 * @param error Error 选填
 * @param context 额外信息
 */
const capture = (
  level: ErrorLevel,
  label: string,
  describe: string,
  error: Error = new Error(''),
  context: CaptureContext = {}
) => {
  try {
    const detail = errorHandler(level, label, describe, error, context)
    const { reason, level: _level, context: _context } = detail

    if (_level === ErrorLevel.info) {
      info(reason, _context)
    } else {
      const err = detail.error
      const reasonStr = reason ? ` (${reason})` : ''
      err.message = `[${label}] - ${describe}${reasonStr}`
      // 使用 withScope 改写事件信息
      Sentry.withScope((scope) => {
        scope.update({
          level,
          ...context
        })
        Sentry.captureException(err, {
          level: _level,
          ..._context
        }) // 改写后的错误
      })
    }
  } catch (e) {
    console.error('sentry capture inner error', error)
  }
}

/**
 * 自定义日志
 * @param name 日志信息名
 * @param context 日志详情键值对
 */
const info = (name: string, context: CaptureContext): void => {
  try {
    Sentry.captureMessage(name || 'Something went wrong', context)
  } catch (e) {
    console.error('Sentry captureMessage', e)
  }
}

/**
 * 需要加急到你的错误
 * @param label 标记 标示自己的业务模块 必填
 * @param describe 自定义信息 必填
 * @param error Error 必填
 * @param context 额外信息
 */
const fatal = (
  label: string,
  describe: string,
  err: Error,
  context?: CaptureContext
): void => capture(ErrorLevel.fatal, label, describe, err, context)

/**
 * 普通错误
 * @param label 标记 标示自己的业务模块 必填
 * @param describe 自定义信息 必填
 * @param error Error 必填
 * @param context 额外信息
 */
const error = (
  label: string,
  describe: string,
  err: Error,
  context?: CaptureContext
): void => capture(ErrorLevel.error, label, describe, err, context)

/**
 * 警告
 * @param label 标记 标示自己的业务模块 必填
 * @param describe 自定义信息 必填
 * @param error Error 必填
 * @param context 额外信息
 */
const warn: CaptureFunc = (
  label: string,
  describe: string,
  err: Error,
  context?: CaptureContext
) => capture(ErrorLevel.warning, label, describe, err, context)

const sentry = {
  fatal,
  error,
  warn,
  info
}

export default sentry
