import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { bitlayer } from '@wagmi/core/chains'

const icon = chainsSvgs.bitlayer
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...bitlayer,
    rpcUrls: {
      default: {
        http: [
          'https://rpc.ankr.com/bitlayer/021add687c9a1fb60565842ee19bef5e200ffd2056ce88392c7ed51c950d2724',
          'https://rpc.bitlayer.org'
        ]
      }
    }
  },
  id: 200_901,
  type: 'EVM',
  name: 'Bitlayer',
  icon: icon,
  networkType
}

export default chainInfo
