import { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { RouterProvider } from 'react-router-dom'
import { routers, routes } from 'router'
import { WebAppProvider, useWebApp } from '@vkruglikov/react-telegram-web-app'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Providers from './proviers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PageLoading } from './components/PageLoading'
import useInitTheme from './stores/userStore/hooks/useInitTheme'
import commonStore from './stores/commonStore'
import useApp from './hooks/oauth/useApp'
import { tgDeskPlatform } from './utils/telegram'
import { beforeCapture } from './router/ErrorBoundary'
import isError from './utils/sentry/logger/isError'
import logger from './utils/sentry/logger'
import { useFullScreen } from './hooks/tg/useFullScreen'

//get env mode
function App() {
  const webapp = useWebApp()
  const queryClient = new QueryClient()
  useInitTheme()
  useFullScreen()
  const { isValidActions } = useApp()

  const initTMA = () => {
    try {
      !isValidActions && webapp?.expand()
      webapp?.enableClosingConfirmation()
      webapp?.disableVerticalSwipes()
      const color = localStorage.theme === 'dark' ? '#12122a' : '#ffffff'
      webapp?.setHeaderColor(color)
      webapp?.setBackgroundColor(color)
      webapp?.setBottomBarColor(color)
      const pla = window.Telegram?.WebApp.platform
      if (!tgDeskPlatform.includes(pla) && !isValidActions) {
        webapp?.requestFullscreen && webapp?.requestFullscreen()
      }
    } catch (error) {
      if (isError(error)) {
        const extra = {
          botApiVersion: webapp?.version,
          platform: webapp?.platform
        }
        logger.warn('Telegram', 'init tma err', error, { extra })
      }
    }
  }

  useEffect(() => {
    window.initialUrlParams = location.search + location.hash.toString()
  }, [])

  useEffect(() => {
    initTMA()
    commonStore.updateInitViewHeight(window.Telegram.WebApp.viewportHeight)
    const tgViewHeightChange = () => {
      const minHeight = Math.min(
        window.Telegram.WebApp.viewportHeight,
        window.Telegram?.WebApp?.isFullscreen
          ? window.innerHeight + 94
          : window.innerHeight
      )
      console.log(
        'update view',
        window.Telegram.WebApp.viewportHeight,
        minHeight
      )
      commonStore.updateViewHeightChange(minHeight)
      if (window.Telegram.WebApp.isExpanded) {
        commonStore.updateStaticViewHeight(
          window.Telegram.WebApp.viewportHeight
        )
      }
    }
    window.Telegram.WebApp.onEvent('viewportChanged', tgViewHeightChange)
    return () => {
      window.Telegram?.WebApp.offEvent('viewportChanged', tgViewHeightChange)
    }
  }, [])

  return (
    <Sentry.ErrorBoundary
      fallback={<PageLoading />}
      beforeCapture={beforeCapture}
    >
      <WebAppProvider
        options={{
          smoothButtonsTransition: true
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Providers>
            <Suspense fallback={<PageLoading />}>
              <ToastContainer theme="dark" />
              <RouterProvider router={routers} />
            </Suspense>
          </Providers>
        </QueryClientProvider>
      </WebAppProvider>
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
