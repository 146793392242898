import { useInitData, useWebApp } from '@vkruglikov/react-telegram-web-app'
import { useEffect, useMemo, useState } from 'react'
import { decodeBase64StartParam, decodeTelegramUrlParameters } from 'utils/sdk'
import useWallet from './useWallet'
import { setSdkHash } from '@/utils/sdk'

export default function useApp() {
  const prefix = 'tomoconnect_'
  const [initDataUnsafe] = useInitData()
  const { sdkReject } = useWallet()
  const webApp = useWebApp()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (initDataUnsafe) setLoading(false)
  }, [initDataUnsafe])

  // actions for wallet
  const actions = useMemo(() => {
    if (initDataUnsafe?.start_param) {
      let params
      try {
        params = decodeBase64StartParam(initDataUnsafe?.start_param)
      } catch (e) {
        // fallback to old method
        const paramsStr = initDataUnsafe?.start_param.slice(prefix.length)
        params = decodeTelegramUrlParameters(paramsStr)
      }
      return params
    }
    return {}
  }, [initDataUnsafe?.start_param])

  const isValidActions = useMemo(() => {
    const isValid = Boolean(actions && actions?.method && actions?.params)
    setSdkHash(isValid ? actions?.params[1] || '' : '')
    return isValid
  }, [actions])

  const isSignPage = useMemo(() => {
    if (
      isValidActions &&
      (actions.method == 'personal_sign' ||
        actions.method == 'eth_signTransaction' ||
        actions.method == 'eth_signETHTransaction' ||
        actions.method == 'eth_signErc20Transaction')
    ) {
      return true
    }
    return false
  }, [actions, isValidActions])

  const webAppReject = async (force?: boolean) => {
    const result = await sdkReject()

    if (force || result) {
      webApp?.close()
    }
  }

  return {
    actions,
    isValidActions,
    isSignPage,
    webAppReject,
    loading
  }
}
