export const tgDeskPlatform = ['macos', 'tdesktop', 'window', 'weba']

export function scrollIntoViewOnTG(
  event: React.FocusEvent,
  delay: number = 300
) {
  if (tgDeskPlatform.includes(window.Telegram?.WebApp.platform)) {
    return
  }

  setTimeout(() => {
    event.target?.scrollIntoView({
      behavior: 'smooth'
    })
  }, delay)
}
