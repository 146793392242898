import { inviteCodeAtom, isPinExistsAtom } from '../state'
import { useAtom } from 'jotai/index'
import { IWeb3ChainType, Web3Type } from '../proviers/web3Provider/type'
import { useCallback, useMemo } from 'react'
import { mnemonicToPrivateKey, recoverSeedWords } from 'utils/wallet'
import { useQuery } from '@tanstack/react-query'

import { mockTronChainId } from 'config/tron'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import walletStore from '@/stores/walletStore'
import { getChainByChainId } from '@/stores/walletStore/utils'
import {
  displayName,
  getBtcWallet,
  userAddressList
} from '@/stores/userStore/utils'
import chains from '@/proviers/web3Provider/chains'
import useUserStore from '@/stores/userStore/hooks/useUserStore'
import { BTCAddressType, getBitCoinTypeBySend } from '@/utils'
import { WalletType } from '@/stores/tokenStore/type/BTCToken'

export type UserType = {
  // nickname: string
  username: string
  token: string
  avatar: string
  id: number
  displayName: string
  bitcoinP2pkhAddress: string
  bitcoinP2trAddress: string
  bitcoinP2shAddress: string
  bitcoinP2wpkhAddress: string
  ethereumAddress: string
  solanaAddress: string
  tronAddress: string
  tonAddress: string
  tonPublicKey?: string
  nickname?: string
  tgId?: string
  tgName?: string
  email?: string
}

export default function useLoginInfo() {
  const [inviteCode, setInviteCode] = useAtom(inviteCodeAtom)
  const { user } = useUserStore()

  const walletAddress = useMemo(() => {
    if (walletStore.chainId == -1) return ''
    const walletType = getChainByChainId(walletStore.chainId)?.type
    const userAddress = userAddressList(user)
    if (walletType == 'EVM') {
      return userAddress?.ethereumAddress
    }
    if (walletType == 'SOL') {
      return userAddress?.solanaAddress
    }
    if (walletType == 'TON') {
      return userAddress?.tonAddress
    }
    if (walletType == 'TONTEST') {
      return userAddress?.tonAddressTest
    }
    if (walletType == 'TRON') {
      return userAddress?.tronAddress
    }
    if (walletType == 'BTC') {
      switch (walletStore.btcWalletType) {
        case 'bitcoinP2Pkh':
          return userAddress?.bitcoinP2pkhAddress
        case 'bitcoinP2Tr':
          return userAddress?.bitcoinP2trAddress
        case 'bitcoinP2Sh':
          return userAddress?.bitcoinP2shAddress
        case 'bitcoinP2Wpkh':
          return userAddress?.bitcoinP2wpkhAddress
        default:
          return userAddress?.bitcoinP2pkhAddress
      }
    }
    if (walletType == 'SUI') {
      return userAddress?.suiAddress
    }
    if (walletType == 'DOGE') {
      return userAddress?.dogeAddress
    }
    return userAddress?.ethereumAddress
  }, [user, walletStore.chainId, walletStore.btcWalletType])

  const evmAddress = user?.ethereumAddress
  const solAddress = user?.solanaAddress
  const tonAddress = user?.tonAddress
  const tonAddressTest = user?.tonAddressTest
  const tronAddress = user?.tronAddress
  const suiAddress = user?.suiAddress
  const cosmosAddress = user?.cosmosAddress
  const tonPublicKey = user?.tonPublicKey
  const dogeAddress = user?.dogeAddress

  const btcAddress = {
    bitcoinP2PkhAddress: user?.bitcoinP2pkhAddress,
    bitcoinP2ShAddress: user?.bitcoinP2shAddress,
    bitcoinP2TrAddress: user?.bitcoinP2trAddress,
    bitcoinP2WpkhAddress: user?.bitcoinP2wpkhAddress
  }

  const btcPubKeys = {
    bitcoinP2PKHPubKeyMain: user?.bitcoinP2pkhPubKeyMain,
    bitcoinP2SHPubKeyMain: user?.bitcoinP2shPubKeyMain,
    bitcoinP2TRPubKeyMain: user?.bitcoinP2trPubKeyMain,
    bitcoinP2WPKHPubKeyMain: user?.bitcoinP2wpkhPubKeyMain,
    bitcoinP2PKHPubKeyTest: user?.bitcoinP2pkhPubKeyTest,
    bitcoinP2SHPubKeyTest: user?.bitcoinP2shPubKeyTest,
    bitcoinP2TRPubKeyTest: user?.bitcoinP2trPubKeyTest,
    bitcoinP2WPKHPubKeyTest: user?.bitcoinP2wpkhPubKeyTest
  }

  const btcWallet = () => {
    console.log({
      btcAddress: getBtcWallet(user, walletStore.btcWalletType)
    })

    return getBtcWallet(user, walletStore.btcWalletType)
  }

  const getbtcPubKeysByType = (type?: BTCAddressType) => {
    const btcWalletType =
      type || getBitCoinTypeBySend(walletStore.btcWalletType)
    const networkType =
      import.meta.env.VITE_TOMO_BTC_NETWORK === 'MAINNET' ? 'Main' : 'Test'
    const key =
      `bitcoin${btcWalletType}PubKey${networkType}` as keyof typeof btcPubKeys
    return btcPubKeys?.[key] || ''
  }
  const getAddressByToken = (
    token: AssetsToken,
    btcWalletType?: WalletType
  ) => {
    if (!token || !user) return ''

    if (token.chainId == chains.btc.id) {
      return getBtcWallet(user, btcWalletType || walletStore.btcWalletType)
    }
    if (token.chainId == chains.solana.id) {
      return user?.solanaAddress
    }
    if (token.chainId == chains.solana.id) {
      return user?.solanaAddress
    }
    if (token.chainId == chains.ton.id) {
      return user?.tonAddress
    }
    if (token.chainId == chains.tonTestnet.id) {
      return user?.tonAddressTest
    }
    if (token.chainId == chains.tron.id) {
      return user?.tronAddress
    }
    if (token.chainId == chains.sui.id) {
      return user?.suiAddress
    }
    if (token.chainId === chains.cosmos.id) {
      return user?.cosmosAddress
    }
    if (token.chainId == chains.doge.id) {
      return user?.dogeAddress
    }
    return user?.ethereumAddress
  }

  const getRenderAddress = (chain: IWeb3ChainType | undefined) => {
    switch (chain?.type) {
      case Web3Type.EVM:
        return user?.ethereumAddress
      case Web3Type.SOL:
        return user?.solanaAddress
      case Web3Type.BTC:
        return btcWallet()
      case Web3Type.TON:
        return user?.tonAddress
      case Web3Type.TONTEST:
        return user?.tonAddressTest
      case Web3Type.TRON:
        return user?.tronAddress
      case Web3Type.COSMOS:
        return user?.cosmosAddress
      case Web3Type.DOGE:
        return user?.dogeAddress
      default:
        return user?.ethereumAddress
    }
  }

  const getBtcWalletTypeByAddress = (address: string) => {
    switch (address) {
      case btcAddress.bitcoinP2PkhAddress:
        return 'bitcoinP2Pkh'
      case btcAddress.bitcoinP2TrAddress:
        return 'bitcoinP2Tr'
      case btcAddress.bitcoinP2ShAddress:
        return 'bitcoinP2Sh'
      case btcAddress.bitcoinP2WpkhAddress:
        return 'bitcoinP2Wpkh'
      default:
        return 'bitcoinP2Pkh'
    }
  }

  return {
    btcType: walletStore.btcWalletType,
    btcWallet,
    user: { ...user, displayName: displayName(user) },
    setWalletType: walletStore.btcWalletTypeActions,
    walletType: walletStore.btcWalletType,
    walletAddress,
    evmAddress,
    solAddress,
    btcAddress,
    tronAddress,
    tonAddress,
    tonAddressTest,
    suiAddress,
    cosmosAddress,
    tonPublicKey,
    dogeAddress,
    setInviteCode,
    inviteCode,
    getAddressByToken,
    btcPubKeys,
    getbtcPubKeysByType,

    getAddressAsChainId: (token: AssetsToken) => {
      if (!token || !user) return ''
      if (token.chainId == 0) {
        if (walletStore.btcWalletType == 'bitcoinP2Pkh') {
          return btcAddress.bitcoinP2PkhAddress
        } else if (walletStore.btcWalletType == 'bitcoinP2Tr') {
          return btcAddress?.bitcoinP2TrAddress
        } else if (walletStore.btcWalletType == 'bitcoinP2Sh') {
          return btcAddress?.bitcoinP2ShAddress
        } else if (walletStore.btcWalletType == 'bitcoinP2Wpkh') {
          return btcAddress?.bitcoinP2WpkhAddress
        }
        return user?.bitcoinP2trAddress
      }
      if (
        token.chainId == chains.solana.id
        // || token.chainId === chains.solDevnet.id //testnet
      ) {
        return user?.solanaAddress
      }
      if (token.chainId == chains.ton.id) {
        return user?.tonAddress
      }
      if (token.chainId == chains.tonTestnet.id) {
        return user?.tonAddressTest
      }
      if (token.chainId == chains.tron.id) {
        return user?.tronAddress
      }
      if (token.chainId === chains.sui.id) {
        return user?.suiAddress
      }
      if (token.chainId === chains.cosmos.id) {
        return user?.cosmosAddress
      }
      if (token.chainId === chains.doge.id) {
        return user?.dogeAddress
      }
      return user?.ethereumAddress
    },
    getRenderAddress,
    userAddress: userAddressList(user),
    defaultWalletId: user?.defaultWalletId,
    getBtcWalletTypeByAddress
  }
}
