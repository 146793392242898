import { chainsSvgs } from 'assets'
import { DEFAULT_PAGE_SETTING, PageSetting } from 'hooks/usePageSetting'
import { atom } from 'jotai'
import { IChainId, IWeb3ChainType } from 'proviers/web3Provider/type'
import { UserType } from '../hooks/useLoginInfo'
import chains from 'proviers/web3Provider/chains'
import { GetBalanceReturnType, PublicClient, WalletClient } from 'viem'
import {
  BackupSetting,
  DEFAULT_BACKUP_SETTING
} from '../hooks/useBackupSetting'
import { ISwapTokenType } from 'pages/swap/hooks/type'
import { atomWithStorage } from 'jotai/utils'
import { TransactionsType } from '../stores/walletStore/hooks/indexDB/indexedDBStore'
import { AssetsToken } from '@/stores/tokenStore/type/AssetsToken'
import {
  TOMO_CONFIG_CHAINS_INFO,
  TOMO_CONFIG_NOT_SHOW_MAX_FEE_TIPS
} from './tomoKeys'
import { HIDDEN_TOKENS, SHOW_TOKENS } from '@/stores/walletStore/utils'
import { Aggregator } from '@/constants/types'

function createPersistenceAtom<T>(name: string, defaultValue: T) {
  const data = localStorage.getItem(name)
  const flag = data === 'undefined' || data === null
  const tempAtom = atom<T>(flag ? defaultValue : JSON.parse(data as string))

  return atom<T>(
    (get) => get(tempAtom),
    (get, set, newObj) => {
      let newValue = newObj
      if (typeof newObj === 'function') {
        newValue = newObj(get(tempAtom))
      }
      set(tempAtom, newValue)
      localStorage.setItem(name, JSON.stringify(newValue))
    }
  )
}
export const currentChainAtom = atom<IWeb3ChainType>(chains.allChain)

export const pageSettingAtom = atom<PageSetting>(DEFAULT_PAGE_SETTING)
export const backupSettingAtom = atom<BackupSetting>(DEFAULT_BACKUP_SETTING)

export const userAtom = atom<UserType | null>(null)

// export const walletTypeAtom = atom<WalletType | null>(null)

export enum SocialType {
  Google = 'google',
  Twitter = 'twitter',
  Apple = 'apple',
  Email = 'email',
  Telegram = 'telegram'
}

export type WalletState = {
  isConnection: boolean
  walletId?: number
  connectType?: 'social'
  socialType?: SocialType
  address?: string
  chainId?: number
}

export const walletStateAtom = createPersistenceAtom<WalletState>(
  'tomo_wallet_state',
  {
    isConnection: false,
    walletId: undefined,
    connectType: undefined,
    socialType: undefined,
    address: undefined,
    chainId: undefined
  }
)

export const installWalletsAtom = atom([])

export type TomoPage = {
  jsx: any
  resolve: (v?: any) => void
  type: 'page' | 'drawer'
  showClose: boolean
}

export const pagesAtom = atom<TomoPage[]>([])

export const clientMapAtom = atom({
  // 0: loading 1: ready
  state: 0 as 0 | 1,
  evmWallet: undefined as WalletClient | undefined,
  evmProvider: undefined as undefined | any
})

export const bioStatusAtom = atom<boolean>(false)
// export const userPhrasAtom = atom<string | undefined>(undefined)
export const bioAvailableAtom = atom<boolean>(false)
export const bioInitedAtom = atom<boolean>(false)

export type IHistoryType = {
  fromAddress: string | undefined
  toAddress: string | undefined
  targetAddress?: string
  fromAmount: string | undefined
  toAmount: string
  nonce: number
  fromSwapTokens: {
    token: AssetsToken
    chain: IWeb3ChainType | undefined
    balance: AssetsToken | undefined
  }
  toSwapTokens: {
    token: AssetsToken
    chain: IWeb3ChainType | undefined
    balance: AssetsToken | undefined
  }
  time: number
  networkFee?: string
  block?: number
  hash: string
  chain?: IWeb3ChainType | undefined
  chainId?: IWeb3ChainType | undefined
  type?: 'OKX' | 'Rango' | ''
  requestId?: string | undefined
  historyType: 'Swap' | 'Send' | 'Approve' | 'Receive' | 'Pay'
  status?: 'success' | 'pending' | 'failed' | 'loading' | 'unknow'

  blocknumber?: string | undefined
  endTime?: number
  gasAmount?: string | undefined

  toHash?: string | undefined
  toHashInfo:
    | {
        blocknumber: string | undefined
        endTime: number
        gasAmount: string | undefined
      }
    | undefined
  routeInfo?: {
    swapperTitle: string
    swapperLogo: string
    aggregatorType: Aggregator | undefined
  }
  source?: 'OKX' | 'TOMO' | undefined
}

export type IOKXHistoryType = {
  amount: string // not formatted
  chainIndex: string
  from: [{ address: string }]
  hitBlacklist: boolean
  itype: string
  methodId: string | undefined // 16hex
  nonce: string | undefined
  symbol: string
  tag: string
  to: [{ address: string; amount?: string }]
  tokenAddress: string
  txFee: string | undefined // formatted
  txHash: string
  txStatus: string
  txTime: string
}

export type ReportHistoryType = {
  chainID: number
  gas: string
  tx: string
  type: 'swap' | 'send'
  userID: number
  requestId: number
  source: string
  sourceType: 'cross' | 'normal'
}

export type ReportSourceType = {
  plat: 'OKX' | 'Rango' | ''
  sourceType: 'cross' | 'normal'
  requestId: string
  time: number
  status: string
  from: {
    chainID: number
    symbol: string
    tokenAddress: string
    amount: string
    decimals: number
  }
  hash: string
  to: {
    chainID: number
    symbol: string
    tokenAddress: string
    amount: string
    decimals: number
  }
  toHash: string
  toAddress: string
  toBlock: string
  routeInfo:
    | {
        aggregatorType: Aggregator | undefined
        swapperLogo: string
        swapperTitle: string
      }
    | undefined
}

// source = {
//   plat: 'okx',
//   sourceType: 'cross',
//   requestId: '',
//   time: 1732036322599,
//   from: {
//     chainID: 56,
//     symbol: 'BNB',
//     tokenAddress: '',
//     amount: '1000000000000000000',
//     decimals: 18
//   },
//   to: {
//     chainID: 10,
//     symbol: 'USDT',
//     tokenAddress: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
//     amount: '1000174',
//     decimals: 6
//   },
//   routeInfo: {
//     aggregatorType: 'Okx',
//     swapperLogo: 'https://static.okx.com/cdn/wallet/logo/biswap.png',
//     swapperTitle: 'BiSwap'
//   }
// }

export const inviteCodeAtom = atom(localStorage.getItem('inviteCode') || '')

export const saveSwapErc20Tokens = createPersistenceAtom<
  | {
      network: IWeb3ChainType
      token: ISwapTokenType
      chainId: number
    }[]
  | undefined
>('saveSwapErc20Tokens', undefined)

export const saveSwapSPLTokensAtom = createPersistenceAtom<
  | {
      network: IWeb3ChainType
      token: ISwapTokenType
      chainId: number
    }[]
  | undefined
>('saveSwapSPLTokens', undefined)

export const isPinExistsAtom = atom<boolean>(false)

export const markertNetworkAtom = atom<IWeb3ChainType>(chains.allChain)
export const markertNetworkPageAtom = atom<IWeb3ChainType>(chains.allChain)
export const markertSearchAtom = atom<boolean>(false)
export const markertSearchKeywordAtom = atom<string>('')
export const navigationAtom = atom<{ from: string; to: string }>({
  from: '',
  to: ''
})

export const assetsHiddenTokensAtom = createPersistenceAtom<string[]>(
  HIDDEN_TOKENS,
  []
)

export const assetsShowTokensAtom = createPersistenceAtom<string[]>(
  SHOW_TOKENS,
  []
)
export const deviceIdAtom = atom<string>('')

export const isSendBioAuthAtom = atom<boolean>(false)
export const forceShowLoginAtom = atom<boolean>(true)
export const sendStatusAtom = atom<'success' | 'loading' | 'confirm'>('confirm')
export const pincodeSeedAtom = atom<number>(-1)

// export const isTelegramReadyAtom = atom<boolean>(false)
export const configChainsInfoAtom = atomWithStorage<{
  chain_id_name: { [key in IChainId]?: string }
}>(TOMO_CONFIG_CHAINS_INFO, { chain_id_name: {} })

export const approveHashAtom = atom<string | undefined>(undefined)
export const swapInputAtom = atom<string>('')
// export const swapInputAtom = atom<string>('1')

export const configNotShowMaxTipsAtom = atomWithStorage<boolean>(
  TOMO_CONFIG_NOT_SHOW_MAX_FEE_TIPS,
  false
)
