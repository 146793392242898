const LaunchHeadImg = () => (
  <div
    className={`launchHeadImg relative mb-[46px]  pt-[101px] ${
      localStorage.theme ?? 'light'
    }`}
  >
    <img
      src="/assets/imgs/launch_bg.png"
      width={277.94}
      height={267.65}
      alt="logo"
      className="mx-auto"
    />
  </div>
)

export default LaunchHeadImg
