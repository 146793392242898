import { pick } from '@/components/tmd/utils/base'
import { ErrorEvent, EventHint } from '@sentry/react'

export const beforeSendSentryError = (event: ErrorEvent, hint: EventHint) => {
  let tag = ''
  if (typeof event?.tags === 'object') {
    //【tag1】【tag2】
    tag = `【${Object.values(event.tags).join('】【')}】`
  }
  const extraError = getErrorString(event?.extra?.error)
  event?.exception?.values?.forEach((value) => {
    if (value?.value) {
      value.value = `${tag}${value.value} ${extraError || ''}`
    }
  })
  return event
}

export const isOauthPage = () => window.location.pathname.startsWith('/oauth')

export const genErrorExtra = (error: Record<string, any> = {}) => {
  return {
    config: genExtraConfig(error?.config),
    error: pick(error, ['code', 'name', 'message', 'stack'])
  }
}
export const genExtraConfig = (config: Record<string, any> = {}) => {
  return pick(config, [
    'baseURL',
    'data',
    'params',
    'headers',
    'method',
    'timeout',
    'url'
  ])
}

export const getUrlPath = (url: string) => url?.split('?')?.[0] || ''

export const getErrorString = (error: unknown) => {
  try {
    if (!error) return ''
    if (typeof error === 'string') return error
    if (error instanceof Error) return error.toString()
    if (typeof error === 'object') return JSON.stringify(error)
    return error
  } catch (error) {
    return ''
  }
}
