// 检查是否是对象或类对象（非 null 且 typeof 为 "object" 或 "function"）
function isObjectLike(value: any) {
  return (
    value != null && (typeof value === 'object' || typeof value === 'function')
  )
}

// 获取对象的内部类型标签
function baseGetTag(value: any) {
  return Object.prototype.toString.call(value)
}

// 检查是否是普通对象（通过原型链来判断）
function isPlainObject(value: any) {
  if (!isObjectLike(value) || baseGetTag(value) != '[object Object]') {
    return false
  }
  const proto = Object.getPrototypeOf(value)
  return proto === null || proto === Object.prototype
}

/**
 * 检查值是否是 `Error`、`EvalError`、`RangeError`、`ReferenceError`、
 * `SyntaxError`、`TypeError` 或 `URIError` 对象。
 *
 * @param {*} value 要检查的值。
 * @returns {boolean} 如果是错误对象，则返回 `true`，否则返回 `false`。
 */
export default function isError(value: any): value is Error {
  // 如果值不是对象，直接返回 false
  if (!isObjectLike(value)) {
    return false
  }

  // 获取对象的类型标签
  const tag = baseGetTag(value)

  // 检查是否是错误类型或类似错误的对象
  return (
    tag === '[object Error]' ||
    tag === '[object DOMException]' ||
    (typeof value.message === 'string' &&
      typeof value.name === 'string' &&
      !isPlainObject(value))
  )
}
