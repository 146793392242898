import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { merlin } from '@wagmi/core/chains'

const icon = chainsSvgs.merlin
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...merlin
  },
  id: 4_200,
  type: 'EVM',
  name: 'Merlin Chain',
  icon: icon,
  networkType
}

export default chainInfo
