import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { polygon } from '@wagmi/core/chains'

const icon = chainsSvgs.polygonSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...polygon,
    rpcUrls: {
      default: {
        http: [
          'https://polygon-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO',
          'https://rpc.ankr.com/polygon/c2d7e8a3db5dce62e202db3d28cca25e74da5028abbf20764e2961918ba34dfc',
          // 'https://rpc.ankr.com/polygon',
          'https://rpc.ankr.com/polygon/1da4199a20e8ebd6c77f56d6b044eaa83775d6ac7a1c578763a057a66ef24103',
          'https://rpc.ankr.com/polygon/ac79e83cf02a544dbb9b3f4c5d5478b2510b921e7d5739ded8791a932e8de0a6'
        ]
      }
    }
  },
  id: 137,
  type: 'EVM',
  name: polygon.name,
  icon: icon,
  networkType
}

export default chainInfo
