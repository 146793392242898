import classNames from 'classnames'
import { SafeAreaIosProps } from './PropsType'
import { isIOS } from '../utils/validate'

export const SafeAreaIos = (props: SafeAreaIosProps) => {
  const { className, ...restProps } = props
  return (
    <div
      className={classNames(
        'flex-none',
        [isIOS() ? 'pb-[34px]' : 'pb-4'],
        className
      )}
      {...restProps}
    ></div>
  )
}
