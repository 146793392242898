import { IChainType, IWeb3ChainType } from '@/proviers/web3Provider/type'
import { SolanaTransaction, TronTransaction } from 'rango-sdk'

export interface ITomoToken {
  chain?: string
  chainId: number
  chainType?: IWeb3ChainType['type']
  chainInfo?: IWeb3ChainType
  address: string
  name: string
  symbol?: string
  decimals: number
  logoURI: string
  price: string
}

export interface ITomoNetwork {
  nickname?: string
  chainId?: number
  platform: PlatformType
}

export enum PlatformType {
  EVM = 'evm',
  TON = 'ton',
  TRON = 'tron',
  BITCOIN = 'bitcoin',
  SOLANA = 'solana',
  SUI = 'sui'
}

export enum Aggregator {
  RANGO = 'Rango',
  OKX = 'Okx',
  JUPITER = 'Jupiter',
  STONFI = 'Ston.fi'
}

export enum DexTag {
  FAST = 'fast',
  MAX = 'max',
  BEST = 'best'
}

export interface DexRouteInfo {
  estimateGasFee: string
  estimateGasValue?: string
  estimateTime: number
  minimumReceived: string
  aggregatorType: Aggregator
  originRoute?: string
  swapData?: any
  rangoRequestId?: string
  swapperLogo: string
  swapperTitle: string
  minReceived?: string
  priorityPrice?: number
  tag?: DexTag[]
}

export interface DexTransaction {
  fromToken: ITomoToken
  toToken: ITomoToken
  fromAddress: string
  targetAddress?: string // send target wallet
  toAddress: string
  approveTo?: string | null
  approveData?: string | null
  approveDexContractAddress?: string
  value?: number | string
  gasFee?: number | string
  maxGas?: number | null
  gasPrice?: string | null
  maxFeePerGas?: string | null
  maxPriorityFeePerGas?: string | null
  data?: any
  memo?: string | null
  instructions?: any
  recentBlockhash?: string | null
  signatures?: any
  serializedMessage?: any
  txType?: string
  needApprove?: boolean
  originTron?: TronTransaction
  params?: CreateTxParams
  rangoSolData?: SolanaTransaction
  rangoRequestId?: string
  randomKeyAccount?: string[]
  jupiterSolData?: IJupiterSolData
  signatureDataFunc?: string
}

export interface IExtraInfo {
  solAddress?: string
}

export interface IJupiterSolData {
  swapTransaction: string
  [objKey: string]: any
}

export type Asset = {
  blockchain: string
  address: string | null
  symbol: string
}
export interface SwapQuoteReq {
  from: Asset
  to: Asset
  amount: string
  slippage?: number
}

export interface SwapRequest {
  from: Asset
  to: Asset
  amount: string
  fromAddress: string
  toAddress: string
  disableEstimate: boolean
  enableCentralizedSwappers: boolean
  slippage?: number
  contractCall?: boolean
}

export interface OkxApproveData {
  data: string
  dexContractAddress: string
  gasLimit: string
  gasPrice: string
}

export interface CreateTxParams {
  fromToken: ITomoToken
  toToken: ITomoToken
  fromNetwork: ITomoNetwork
  toNetwork: ITomoNetwork
  amount: string
  fromAddress: string
  toAddress: string
  routeInfo: DexRouteInfo
  slippage?: number
  contractCall?: boolean
  rangoApiKey: string
  fromChain: IChainType | undefined
  toChain: IChainType | undefined
  extraInfo: IExtraInfo
  referrerInfo: {
    referrerFee: number
    referrerAddress: string
  }[]
}
