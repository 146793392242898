import { useEffect, useState } from 'react'
import commonStore from '..'
import { reaction } from 'mobx'
import { FeeMode } from '@/components/FeeSelect'

const useCommonStore = () => {
  const [viewHeight, setView] = useState(() => commonStore.viewHeight)
  const [staticHeight, setStatic] = useState(() => commonStore.staticHeight)
  const [mayKeyboardOpen, setMayKeyboardOpen] = useState(
    () => commonStore.mayKeyboardOpen
  )
  const [feeMode, setFeeMode] = useState(() => commonStore.feeMode)
  useEffect(() => {
    const viewReaction = reaction(
      () => commonStore.viewHeight,
      (v) => setView(v)
    )
    const staticReaction = reaction(
      () => commonStore.staticHeight,
      (v) => setStatic(v)
    )
    const keyboardReaction = reaction(
      () => commonStore.mayKeyboardOpen,
      (v) => setMayKeyboardOpen(v)
    )
    const feeModeReaction = reaction(
      () => commonStore.feeMode,
      (v) => setFeeMode(v)
    )
    return () => {
      feeModeReaction()
      viewReaction()
      staticReaction()
      keyboardReaction()
    }
  }, [])

  return {
    viewHeight,
    feeMode,
    setFeeMode: (info: FeeMode) => commonStore.feeModeActions(info),
    staticHeight,
    mayKeyboardOpen
  }
}

export default useCommonStore
