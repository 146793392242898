import { useEffect } from 'react'
import { errorBoundarySvg } from 'assets'
import { isRouteErrorResponse, useRouteError } from 'react-router-dom'

import logger from '@/utils/sentry/logger'
import isError from '@/utils/sentry/logger/isError'
import { useNavigate } from 'react-router-dom'

// ErrorBoundary has a children
// Eefresh btn in RouteErrorBoundary is refresh the route
const RouteErrorBoundary = () => {
  const error = useRouteError()
  const navigate = useNavigate()
  const refreshRoute = () => {
    navigate(0) // 刷新当前路由
  }

  useEffect(() => {
    if (isError(error) && !isRouteErrorResponse(error)) {
      logger.fatal('Page', 'page crash err', error)
    }
  }, [error])

  if (isRouteErrorResponse(error)) {
    // 有route action相关的错误才会触发，不用处理。
    return (
      <div style={{ padding: 10 }}>
        <p>Some Errors happened!</p>
      </div>
    )
  } else if (isError(error)) {
    return (
      <>
        <div className="flex flex-col items-center px-[20px]">
          <img src={errorBoundarySvg} alt="" className="mt-[122px]" />
          <div className="mt-[24px] text-xl font-[700] leading-[20.8px]">
            Error network
          </div>
          <div
            style={{
              color: '#666'
            }}
            className="mt-[10px] flex text-center"
          >
            Your network is experiencing some problems, please check and try
            refreshing
          </div>
          <button
            onClick={() => refreshRoute()}
            className="mt-[24px] h-[48px] w-[240px] rounded-[8px] bg-red px-[16px] py-[12px] text-xl font-[500] text-white"
          >
            Refresh
          </button>
        </div>
      </>
    )
  } else {
    return null
  }
}
export default RouteErrorBoundary
