import useUserStore from '@/stores/userStore/hooks/useUserStore'
import mixpanel from '@/utils/mixpanel'
import React from 'react'

const useMixpanel = () => {
  const { user } = useUserStore()

  const checkBlock = import.meta.env.VITE_NODE_ENV === 'production'
  const init = () => {
    if (checkBlock)
      try {
        mixpanel.identify(user.id)
        mixpanel.people.set({
          $name: user.nickname || user.username,
          $email: user.email
          // referer: ''
        })
        trackUserInit({
          $name: user.username,
          $email: user.email || '',
          telegram_id: Number(user.tgId),
          telegram_name: user.tgName || '',
          ethereum_address: user.ethereumAddress,
          solana_address: user.solanaAddress,
          bitcoin_p2pkh_address: user.bitcoinP2pkhAddress,
          bitcoin_p2tr_address: user.bitcoinP2trAddress,
          bitcoin_p2sh_address: user.bitcoinP2shAddress,
          bitcoin_p2wpkh_address: user.bitcoinP2wpkhAddress,
          ton_address: user.tonAddress,
          tron_address: user.tronAddress,
          sui_address: user.suiAddress,
          referer: user.inviteCode
        })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackBalance = ({
    fromAddress,
    symbol,
    amount,
    totalTokenUsd
  }: {
    fromAddress: string
    symbol: string
    amount: string
    totalTokenUsd: string
  }) => {
    if (checkBlock)
      try {
        init()
        mixpanel.track('token_balance', {
          wallet_address: fromAddress,
          token_symbol: symbol,
          amount: amount,
          usd: totalTokenUsd,
          platform: 'tg'
        })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackSwapFail = ({
    fromChain,
    toChain,
    fromTokenSymbol,
    toTokenSymbol,
    fromAmount,
    toAmount,
    totalTokenUsd,
    gasUsd,
    from_address,
    fail_reason,
    fail_code
  }: {
    fromChain: string
    toChain: string
    fromTokenSymbol: string
    toTokenSymbol: string
    fromAmount: string
    toAmount: string
    totalTokenUsd: string
    gasUsd: string
    from_address: string
    fail_reason: string
    fail_code: string
  }) => {
    if (checkBlock)
      try {
        init()
        mixpanel.track('swap_fail_reason', {
          chain: fromChain,
          token_symbol: fromTokenSymbol,
          to_chain: toChain,
          to_token_symbol: toTokenSymbol,
          from_amount: fromAmount,
          to_amount: toAmount,
          usd: totalTokenUsd,
          gas_usd: gasUsd,
          from_address: from_address,
          fail_reason: fail_reason,
          platform: 'tg',
          fail_code: fail_code
        })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackSwap = ({
    fromChain,
    toChain,
    fromTokenSymbol,
    toTokenSymbol,
    fromAmount,
    toAmount,
    totalTokenUsd,
    providerType,
    providerTypeInfo,
    fromAddress,
    status,
    gasUsd
  }: {
    fromChain: string
    toChain: string
    fromTokenSymbol: string
    toTokenSymbol: string
    fromAmount: string
    toAmount: string
    totalTokenUsd: string
    fromAddress: string
    providerType: string
    providerTypeInfo: string
    status: boolean // true = success || false = failed
    gasUsd: string
  }) => {
    if (checkBlock)
      try {
        init()
        mixpanel.track('swap', {
          chain: fromChain,
          token_symbol: fromTokenSymbol,
          to_chain: toChain,
          to_token_symbol: toTokenSymbol,
          from_amount: fromAmount,
          to_amount: toAmount,
          from_address: fromAddress,
          providerTypeInfo: providerTypeInfo,
          usd: totalTokenUsd,
          provider_type: providerType,
          status: status,
          platform: 'tg',
          gas_usd: gasUsd
        })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackSend = ({
    chain,
    tokenSymbol,
    amount,
    totalTokenUsd,
    status,
    gasUsd,
    fromAddress,
    toAddress
  }: {
    chain: string
    tokenSymbol: string
    amount: string
    totalTokenUsd: string
    fromAddress: string
    toAddress: string
    status: boolean // true = success || false = failed
    gasUsd: string
  }) => {
    if (checkBlock)
      try {
        init()
        mixpanel.track('token_transfer', {
          chain: chain.toLocaleUpperCase(),
          token_symbol: tokenSymbol,
          amount: amount,
          usd: totalTokenUsd,
          from_address: fromAddress,
          to_address: toAddress,
          gas_usd: gasUsd,
          platform: 'tg',
          status: status
        })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackQuote = (params: {
    fromChain: string
    toChain: string
    fromAmount: string
    slippage: number
    fromAddress: string
    providerType: string
    failReason: string
    providerTypeInfo: string
  }) => {
    if (checkBlock)
      try {
        init()
        mixpanel.track('swap_quote', { ...params, platform: 'tg' })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackConfirm = (params: {
    fromChain: string
    toChain: string
    fromAmount: string
    fromAddress: string
    providerType: string
    providerTypeInfo: string
  }) => {
    if (checkBlock)
      try {
        init()
        mixpanel.track('swap_confirm', { ...params, platform: 'tg' })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackUserPoints = (params: {
    evmAddress: string
    taskName: string
    userId: string
    tgName: string
    tgId: string
    points: string
  }) => {
    if (checkBlock)
      try {
        init()
        mixpanel.track('user_task_points', { ...params, platform: 'tg' })
      } catch (error) {
        console.warn('mixpanel', error)
      }
  }

  const trackUserInit = (params: {
    $name: string
    $email: string
    telegram_id: number
    telegram_name: string
    ethereum_address: string
    solana_address: string
    bitcoin_p2pkh_address: string
    bitcoin_p2tr_address: string
    bitcoin_p2sh_address: string
    bitcoin_p2wpkh_address: string
    ton_address: string
    tron_address: string
    sui_address: string
    referer: string
  }) => {
    if (checkBlock) mixpanel.people.set_once({ ...params, platform: 'tg' })
  }

  return {
    init,
    trackBalance,
    trackSwap,
    trackQuote,
    trackConfirm,
    trackSwapFail,
    trackSend,
    trackUserPoints,
    trackUserInit
  }
}

export default useMixpanel
