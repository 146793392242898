import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'
import { zksync } from '@wagmi/core/chains'

const icon = chainsSvgs.zksyncPng
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    ...zksync,
    rpcUrls: {
      default: {
        http: [
          'https://zksync-mainnet.g.alchemy.com/v2/DR7Jtd4NSYTtAY7Heme8ml-j6oBCZgGO',
          'https://rpc.ankr.com/zksync_era/73839306f1cd63a8a214a0a26748a083d8e2062d731c938171bc88582f72580a',
          'https://mainnet.era.zksync.io'
        ]
      }
    }
  },
  id: zksync.id,
  type: 'EVM',
  name: 'zkSync Era', //zksync.name,
  icon: icon,
  networkType
}

export default chainInfo
