import classNames from 'classnames'
import { ButtonProps, ButtonSize } from './PropsType'
import LoadingSvg from '../svgs/loading.svg?react'

export function Button(props: ButtonProps) {
  const {
    theme = 'primary',
    shape = 'default',
    size = 'default',
    disabled,
    block = false,
    loading = false,
    className,
    onClick,
    ...otherProps
  } = props

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (!loading && !disabled && onClick) {
      onClick(event)
    }
  }

  const baseStyles = 'flex items-center justify-center font-medium'
  let disabledStyles = 'cursor-not-allowed !bg-bg3 !text-t4'
  const blockStyles = block ? 'w-full' : 'px-4'

  const themeStyles = {
    primary: 'bg-red text-white',
    white: 'bg-[#F2F4F6] text-btn',
    ghost: 'bg-bg1 text-base text-t1 border-[0.5px] border-t1',
    empty: 'bg-transparent text-[#F21F7F] border-[1px] border-[#F21F7F]',
    black: 'bg-t1 text-bg5 text-xs font-normal'
  }

  const extraDisabledStyles: any = {
    empty: 'border-t4 border-[0.2px]'
  }

  disabledStyles += ' ' + (extraDisabledStyles[theme] || '')

  const shapeStyles = shape === 'round' ? 'rounded-full' : 'rounded-lg'

  const sizeStyles: Record<ButtonSize, string> = {
    default: 'h-9 text-sm',
    small: 'h-8 text-sm',
    large: 'h-13 text-base',
    icon: 'size-10 p-0'
  }

  let loadingClasses = 'mr-[7px] size-5 flex-none text-white'
  if (theme === 'ghost') {
    loadingClasses = 'mr-[7px] size-5 flex-none text-black'
  }

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      {...otherProps}
      className={classNames(
        baseStyles,
        { [disabledStyles]: disabled },
        blockStyles,
        themeStyles[theme],
        shapeStyles,
        sizeStyles[size],
        className
      )}
    >
      {loading && (
        <LoadingSvg
          className={classNames('tmd-btn-loading mr-[7px] size-5 flex-none', {
            'text-black': theme === 'ghost',
            'text-white': theme !== 'ghost'
          })}
        />
      )}

      {otherProps.children}
    </button>
  )
}
