import * as Sentry from '@sentry/react'

import { ErrorLevel } from './const'

/**
 * Enum representing different business types.
 */
export const enum Business {
  Trade = 'Trade',
  OAUTH = 'OAuth' // auth-bot
}

interface Extra {
  [key: string]: unknown
}
interface Context {
  level?: ErrorLevel
  extra?: Extra
  tags?: {
    [key: string]: string | number
  }
}

/**
 * Format the `extra` object by excluding any keys with values of `null`,
 * `undefined`, or an empty string.
 * @param extra The object to format.
 * @returns The formatted object.
 */
export const format = (extra: Extra = {}): Extra => {
  if (!extra) return {}
  const newExtra: Extra = {}
  for (const key in extra) {
    if (extra[key] !== null && extra[key] !== undefined && extra[key] !== '') {
      if (extra[key] instanceof Error) {
        newExtra[key] = extra[key].toString()
      } else if (typeof extra[key] === 'object') {
        newExtra[key] = JSON.stringify(extra[key])
      } else {
        newExtra[key] = extra[key]
      }
    }
  }
  return newExtra
}
