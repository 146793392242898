import { chainsSvgs } from 'assets'
import { IWeb3ChainType, IWeb3NetworkType } from '../../type'

const icon = chainsSvgs.tronSvg
const networkType: IWeb3NetworkType = 'main'

const chainInfo: IWeb3ChainType = {
  chain: {
    id: 19484,
    name: 'TRON',
    nativeCurrency: { decimals: 6, name: 'TRON', symbol: 'TRX' }
  },
  id: 19484,
  type: 'TRON',
  name: 'Tron',
  icon: icon,
  networkType
}

export default chainInfo
